import React from "react";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Typography from "@mui/material/Typography";

import "./Pages.css"

const NotFound = () => {
    return (
        <div className="alignVertical">
            <SentimentVeryDissatisfiedIcon
                size="large"
                style={{
                    fill: "rgba(204,214,246,255)",
                    fontSize: "1000%",
                }}
            />
            <Typography
                id="modal-modal-title"
                variant="h4"
                align={"center"}
                className="mainColor"
            >Oops! The requested page cannot be found. Please check the URL and try again.</Typography>
        </div>
    );
}

export default NotFound;
