import React from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

import Grid from "@mui/material/Grid";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler
);

const dataPie = {
  labels: ["Technical Informatics", "Medical informatics", "Datascience"],
  datasets: [
    {
      data: [25, 55, 20],
      backgroundColor: ["#a1b1b3", "#64ffda", "#77c3ec"],
      borderWidth: 0,
    },
  ],
};

const optionsPie = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "rgb(246, 246, 246)",
      },
      display: true,
      position: "bottom",
      align: "end",
      onClick: (e) => e.stopPropagation(),
    },
  },
};

const optionsLine = {
  scales: {
    y: {
      border: {
        display: false,
      },
      ticks: {
        color: "rgb(246, 246, 246)",
      },
      suggestedMax: 5.0,
      beginAtZero: false,
      reverse: true,
      grid: {
        color: "rgb(246, 246, 246)",
        display: true,
      },
    },
    x: {
      ticks: {
        color: "rgb(246, 246, 246)",
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.5,
    },
    point: {
      radius: 3,
      pointBackgroundColor: "#cafff2",
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        display: false,
        color: "rgb(246, 246, 246)",
      },
      position: "top",
    },
    title: {
      display: false,
      text: "Way to finish",
    },
  },
};

const labelsLine = [
  "16.05.2018",
  "07.11.2018",
  "05.12.2018",
  "08.02.2019",
  "27.02.2019",
  "30.03.2019",
  "29.03.2019",
  "19.07.2019",
  "13.09.2019",
  "17.09.2020",
  "24.08.2020",
  "26.08.2020",
  "04.09.2020",
  "26.02.2021",
  "01.11.2019",
  "31.12.2021",
];

const dataSet = [
  "2.7",
  "1.7",
  "4.0",
  "2.7",
  "4.0",
  "1.0",
  "1.7",
  "1.3",
  "3.3",
  "2.3",
  "1.7",
  "3.3",
  "2.7",
  "1.0",
  "1.0",
  "1.0",
];

const dataLine = {
  labelsLine,
  datasets: [
    {
      fill: "start",
      label: "Grades",
      data: labelsLine.map((v, i) => ({ x: v, y: dataSet[i] })), // [{x: '2018', y: 20}, {x: '2019', y: 10}, {x: '2020', y: 1}, {x: '2021', y: 15}],
      borderColor: "#004f52",
      backgroundColor: "rgba(100,255,218,255)",
    },
  ],
};

const render = (
  <div>
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="outerContainer">
          <div className="innerContainer">
            <div className="leftGraph">
              <Pie data={dataPie} options={optionsPie} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="outerContainer">
          <div className="innerContainer">
            <div className="rightGraph">
              <Line data={dataLine} options={optionsLine} />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
);

const Master = () => {
  return (
    <div>
      <div>{render}</div>
    </div>
  );
};
export default Master;
