import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import Grid from "@mui/material/Grid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const optionsBar = {
  maintainAspectRatio: false,
  indexAxis: "x",
  scales: {
    y: {
      border: {
        display: false,
      },
      ticks: {
        color: "rgb(246, 246, 246)",
      },
      suggestedMax: 100,
      suggestedMin: 0,
      beginAtZero: false,
      grid: {
        color: "#fffafa",
        display: true,
      },
    },
    x: {
      ticks: {
        color: "rgb(246, 246, 246)",
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        display: false,
      },
      position: "right",
    },
    title: {
      display: false,
      text: "Bar Chart",
    },
  },
};

const labelsBar = ["A2 (2014)", "B1 (2015)", "B2 (2016)"];

const dataSet = ["80", "87", "85"];

const dataBar = {
  labelsBar,
  datasets: [
    {
      label: "Grades",
      data: labelsBar.map((v, i) => ({ x: v, y: dataSet[i] })), // [{x: '2018', y: 20}, {x: '2019', y: 10}, {x: '2020', y: 1}, {x: '2021', y: 15}],
      borderColor: "rgb(30, 52, 81)",
      backgroundColor: "rgba(100,255,218,255)",
    },
  ],
};

const render = (
  <div>
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={8}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="statisticContainerOut">
          <Bar data={dataBar} options={optionsBar} />
        </div>
      </Grid>
    </Grid>
  </div>
);

const German = () => {
  return (
    <div>
      <div>{render}</div>
    </div>
  );
};
export default German;
