import "./App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "./Layout/Footer";
import NavBar from "./Layout/NavBar";

import Typography from "@mui/material/Typography";

function App() {
  return (
    <div className="App">
      <Router>

        <NavBar />

        <Footer>
          <Typography variant="title">Footer Text</Typography>
        </Footer>
      </Router>
    </div>
  );
}

export default App;
