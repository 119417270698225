import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import "./Contact.css";

import Typewriter from "typewriter-effect";

/* Import icons */
import TelegramIcon from "@mui/icons-material/Telegram";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import HubIcon from "@mui/icons-material/Hub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const Contact = () => {
  const [opacity, setOpacity] = useState(false);

  useEffect(() => {
    setOpacity(false);
    setTimeout(() => {
      setOpacity(true);
    }, 1);
  }, []);

  return (
    <div className="mainContainer">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        display="flex"
        justifyContent="center"
        direction="row"
        alignItems="center"
        minHeight="75vh"
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          <Card
            sx={{
              width: { xs: 300, sm: 400, md: 500 },
              height: { xs: 500, sm: 400, md: 400 },
              backgroundColor: "transparent",
              borderRadius: 5,
              boxShadow: "none",
            }}
          >
            <Item>
              <div className="contactCardOutterContainer outterStyleGetInTouch">
                <div className={opacity ? "show" : "hide"}>
                  <div className="contactCardContentContainer">
                    <div className="getInTouchContent">
                      <div className="contactHeaderIcon">
                        <WavingHandIcon
                          size="large"
                          style={{
                            fill: "rgba(204,214,246,255)",
                            fontSize: "200%",
                          }}
                        />
                      </div>
                      <div className="getInTouchContentHeader">
                        <Typography
                          display="inline"
                          variant="h4"
                          component="div"
                        >
                          If you have{" "}
                        </Typography>
                        <div className="typeWriterContactContainer">
                          <Typewriter
                            options={{
                              strings: ["a Question", "an Idea", "a Project"],
                              autoStart: true,
                              loop: true,
                              wrapperClassName: "contactMeTypeWriterText",
                              cursorClassName: "contactMeTypeWriterCursor",
                              cursor: "_",
                              pauseFor: 2000,
                              delay: 100,
                            }}
                          />
                        </div>
                        <Typography variant="h4" component="div">
                          that you need help with. Then
                        </Typography>
                        <br />
                        <Typography
                          variant="h4"
                          component="div"
                          className="colorGreen"
                        >
                          Contact me
                        </Typography>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Item>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          alignContent="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Card
            sx={{
              width: { xs: 350, sm: 400, md: 500 },
              height: { xs: 350, sm: 400, md: 350 },
              backgroundColor: "rgb(24, 36, 68)",
              borderRadius: 5,
            }}
          >
            <div className="contactCardOutterContainer contactOutterStyles">
              <div className={opacity ? "show" : "hide"}>
                <div className="contactCardContentContainer">
                  <div className="contactCardHeader">
                    <div className="contactTextEffectTitle">
                      <Typography gutterBottom variant="h4" component="div">
                        Sociale Networks
                      </Typography>
                      <div className="contactHeaderIcon">
                        <HubIcon
                          size="large"
                          style={{
                            fill: "rgba(204,214,246,255)",
                            fontSize: "200%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contactSubCardsContent">
                    <Typography gutterBottom variant="h5" component="div">
                      Find me on Social Networks
                    </Typography>
                    <div className="contentIcons">
                      <div className="contentIcon">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://de.linkedin.com/in/emzari-abzhandadze-2492b0ab"
                          }
                        >
                          <LinkedInIcon
                            size="large"
                            className="contactIcon"
                            style={{
                              fill: "rgba(204,214,246,255)",
                              fontSize: "140%",
                            }}
                          />
                        </a>
                      </div>
                      <div className="contentIcon">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/emzari.abzhandadze"
                          target="_blank"
                        >
                          <FacebookIcon
                            size="large"
                            className="contactIcon"
                            style={{
                              fill: "rgba(204,214,246,255)",
                              fontSize: "140%",
                            }}
                          />
                        </a>
                      </div>
                      <div className="contentIcon">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/emzari.abzh"
                          target="_blank"
                        >
                          <InstagramIcon
                            size="large"
                            className="contactIcon"
                            style={{
                              fill: "rgba(204,214,246,255)",
                              fontSize: "140%",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          alignContent="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Card
            sx={{
              width: { xs: 350, sm: 400, md: 500 },
              height: { xs: 350, sm: 400, md: 350 },
              backgroundColor: "rgb(24, 36, 68)",
              borderRadius: 5,
            }}
          >
            <div className="contactCardOutterContainer contactOutterStyles">
              <div className={opacity ? "show" : "hide"}>
                <div className="contactCardContentContainer">
                  <div className="contactCardHeader">
                    <div className="contactTextEffectTitle">
                      <Typography gutterBottom variant="h4" component="div">
                        E-Mail
                      </Typography>
                      <div className="contactHeaderIcon">
                        <TelegramIcon
                          size="large"
                          style={{
                            fill: "rgba(204,214,246,255)",
                            fontSize: "200%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contactSubCardsContent">
                    <Typography gutterBottom variant="h5" component="div">
                      Write me an E-Mail
                    </Typography>
                    <div className="contentIcons">
                      <div className="contentIcon">
                        <a href="mailto:emzari.abzhandadze@gmail.com?subject=Contact Form&body=Please contact me">
                          <EmailIcon
                            size="large"
                            className="contactIcon"
                            style={{
                              fill: "rgba(204,214,246,255)",
                              fontSize: "140%",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>

      <br />
    </div>
  );
};

export default Contact;
