import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import "./../Pages.css";
import "./AboutMe.css";

import { Typography } from "@mui/material";

import ProfilePicture from "./../../../Img/MeOriginal2.png";

/* Import Typewriter Effect */
import Typewriter from "typewriter-effect";

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
  boxShadow: "none",
}));

class Home extends Component {
  render() {
    return (
      <div className="mainContainer">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}
          display="flex"
          justifyContent="left"
          direction="row"
          alignItems="center"
          minHeight="75vh"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            style={{ height: "100%", width: "50%" }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
                minHeight: 830,
              }}
            >
              <Item>
                <div className="aboutMeContainer">
                  <Typography gutterBottom variant="h5" component="div">
                    WELCOME TO MY PAGE
                  </Typography>
                  <div className="marginTop30">
                    <Typography display="inline" variant="h3" component="div">
                      I'm{" "}
                      <Typography
                        display="inline"
                        variant="h3"
                        component="div"
                        className="colorGreen"
                      >
                        Emzari Abzhandadze
                      </Typography>
                    </Typography>
                    <div className="typeWriterContainer">
                      <div className="startingWord">a</div>
                      <Typewriter
                        options={{
                          strings: [
                            "Full-Stack Engineer.",
                            "Professional Coder.",
                            "Scientific Researcher.",
                          ],
                          wrapperClassName: "aboutMeTypeWriterText",
                          cursorClassName: "aboutMeTypeWriterCursor",
                          cursor: "_",
                          autoStart: true,
                          loop: true,
                          pauseFor: 2000,
                          delay: 100,
                          deleteSpeed: "natural",
                        }}
                        
                      />
                    </div>
                  </div>
                  <div className="aboutMeContent">
                    <div className="marginTop30">
                      <Typography
                        variant="h5"
                        component="div"
                        className="marginTop10"
                      >
                        I possess computer science expertise that enables me to create a wide range of software, ranging from simple applications to complex, large-scale systems. 
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        className="marginTop15"
                      >
                        My proficiency extends to various software engineering principles, including design patterns, software architecture, software testing, and Quality Assurance. 
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        className="marginTop15"
                      >
                        Leveraging my scientific background, I can develop software solutions that are not only efficient but also reliable. 
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        className="marginTop15"
                      >
                        Moreover, I possess the ability to conduct research and analyze existing solutions to uncover potential enhancements and optimizations. 
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        className="marginTop15"
                      >
                        With my blend of scientific education and hands-on experience, I am confident in my capacity to tackle any software development challenge and deliver effective solutions. 
                      </Typography>
                      <div className="marginTop30 marginBottom30">
                        <Typography
                          variant="h3"
                          component="div"
                          className="marginTop10"
                        >
                          <div className="colorGreen inline happyEffect ">
                            {" "}
                            Happy exploring!
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Item>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            style={{ height: "100%", width: "50%" }}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Item>
              <div className="pictureFrame">
                <div className="profilePictureContainer">
                  <div className="profilePictureBackground"></div>
                  <img src={ProfilePicture} alt="" className="profilePicture" />
                </div>
              </div>
            </Item>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Home;
