import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import Grid from "@mui/material/Grid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const optionsBar = {
  maintainAspectRatio: false,
  indexAxis: "y",
  scales: {
    x: {
      border: {
        display: false,
      },
      ticks: {
        color: "rgb(246, 246, 246)",
      },
      suggestedMax: 100,
      suggestedMin: 0,
      beginAtZero: false,
      grid: {
        display: true,
        color: "#fffafa",
      },
    },
    y: {
      ticks: {
        color: "rgb(246, 246, 246)",
        crossAlign: "far",
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "start",
      labels: {
        color: "rgb(246, 246, 246)",
      },
    },
    title: {
      display: false,
      text: "Bar Chart",
    },
  },
};

const labelsBar = [
  "Reading",
  "Text production",
  "Scientific structures",
  "Listening",
  "Speak",
];

const dataSet = ["70", "67", "84", "76", "75"];

const dataBar = {
  labelsBar,
  datasets: [
    {
      label: "Grades",
      data: labelsBar.map((v, i) => ({ y: v, x: dataSet[i] })), // [{x: '2018', y: 20}, {x: '2019', y: 10}, {x: '2020', y: 1}, {x: '2021', y: 15}],
      borderColor: "rgb(30, 52, 81)",
      backgroundColor: "rgba(100,255,218,255)",
    },
  ],
};

const render = (
  <div>
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Grid
        item
        xs={8}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="statisticContainerOut">
          <Bar data={dataBar} options={optionsBar} />
        </div>
      </Grid>
    </Grid>
  </div>
);

const DSH = () => {
  return (
    <div>
      <div>{render}</div>
    </div>
  );
};
export default DSH;
