import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import "./../Pages.css";
import "./Projects.css";

/* Import Card */
import Card from "@mui/material/Card";

/* Import Icons */
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SchoolIcon from "@mui/icons-material/School";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

/* Import Buttons */
import Button from "@mui/material/Button";

const Projects = () => {
  const [opacity, setOpacity] = useState(false);
  const [headerOpacity, setHeaderOpacity] = useState(false);

  useEffect(() => {
    setHeaderOpacity(true);
    setOpacity(false);

    setTimeout(() => {
      setHeaderOpacity(false);
    }, 6000);

    setTimeout(() => {
      setOpacity(true);
    }, 1);
  }, []);

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      display="flex"
      justifyContent="center"
      direction="row"
      alignItems="center"
      minHeight="75vh"
    >
      <div className="projectsHeader">
        <div className={headerOpacity ? "show" : "hide"}>
          <KeyboardDoubleArrowDownIcon className="headerIcon" />
          <span>Scroll down</span>
          <KeyboardDoubleArrowDownIcon className="headerIcon" />
        </div>
      </div>
      <div className="projectsContainer">
        <div className="timelineBlock timelineBlockRight timelineBlockFirst">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          DMW (Dialektatlas mittleres Westdeutschland)
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        DMW (Dialektatlas mittleres Westdeutschland)
                        The Dialect Atlas of Middle West Germany (DMW) is a 17-year project (2016-2032)
                        that is being carried out at the Universities of Bonn, Münster, Paderborn and Siegen.
                        The aim is the systematic collection, evaluation and interpretation of dialectal or
                        non-standard ways of speaking (varieties) in North Rhine-Westphalia and parts of Lower Saxony
                        and Rhineland-Palatinate on different linguistic levels: vocabulary (lexis),
                        word structure and word formation (morphology), sound structure (phonology)
                        and sentence formation (syntax). The DMW is therefore: digital:
                        The dialectal or non-standard expressions are collected with the help of computers,
                        processed and recorded in detail in a database. The maps are not static,
                        but are generated dynamically in response to specific user requests.
                        Many dialectal expressions can be clicked and listened to on the cards.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Full-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2021 - Present</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen (ZIMT), Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Back-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Database Administration
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Server Administration
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Code-Refactoring
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockLeft betweenContentsMinus300">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Connect.Us
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        Connect.Us is partner for networking all institutions
                        and faculties of the University of Siegen. The
                        opportunities for cooperation range from research and
                        development projects to sponsoring. Connect.Us supports
                        students in identifying cooperation partners and
                        initiating cooperation. Connect.Us offers in the areas
                        of knowledge transfer, entrepreneurship, careers and
                        alumni are aimed both at university members and at
                        external parties.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Part-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2020 - 2021</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Back-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Database Administration
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockRight">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Assistance system for people with mental and physical
                          disabilities by Internet-of-Things
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        Social organizations that offer house-based assisted living for people with mental and physical disabilities find it difficult to keep an overview on the state of their health at all times. For some in need it is very difficult to express their medical needs. There are also dangers to the physical well-being of those in need of assistance outside the medical field, e.g. when someone gets lost in the city, which can have life-threatening consequences and can in the worst case expose them to abuse or rape. An Internet of Things-based assistance system could be a solution for social organizations and thus improve the quality of life of those in need. With automatic data collection and transmission, the data will be securely transmitted to doctors and nurses. For this purpose, the concept for two mobile and web applications will be designed, implemented and evaluated. The assistance system should stay within ethical and legal framework conditions and meet all requirements. This thesis is based on previous research, which has been carried out in Georgia since 2017.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <SchoolIcon className="iconCenter" />
                          <span>Master thesis</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2021</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>
                            Used Technology:
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              React
                            </Button>
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              Firebase
                            </Button>
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              Kotlin
                            </Button>
                          </span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Back-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Database Administration
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Internet of Things
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Mobile App Development
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockLeft betweenContentsMinus400">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Medocs GmbH
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        With digital solutions, Medocs closes the gaps in medical care. The intelligent networking between relevant actors fills the gaps in knowledge with qualitative content and optimizes time-consuming processes. Medocs enables service providers to implement tailor-made diagnostic, therapy, nutritional, behavioral or aftercare plans in gastroenterology and in the future across all indications. The mission of Medocs is to make medical care more efficient. The team behind medocs is constantly working on innovative solutions to continuously improve medical care. Medocs attaches great importance to a pleasant, respectful, but also considerate corporate culture, since Medocs products are efficient and must not endanger the safety or health of those affected. In addition to health, security and the protection of personal data are among the highest concerns of the company.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Part-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2020</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockRight">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Digitized care in the immunodeficiency outpatient
                          clinic: patient empowerment with telemedicine and
                          intelligent sensors
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        The goal of the project is to set up a mobile system for the individual support of outpatient immunotherapies, which makes it easier for patients with temporary or chronic immune deficiencies to document their therapy and coordinates the intake planning. The aim is to reduce the treatment and consultation hours of the doctors by digitizing the communication via smartphone to the treating doctor. This increase in efficiency should then enable more individual patient care, in that the therapy deficits become visible more quickly than before become. In addition, the possibility of evaluating the data for efficient studies should take place. Thus, the therapy and medication can be continuously improved. By using a cloud infrastructure, both the patient and the doctor can view, manipulate or create the data from anywhere.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <SchoolIcon className="iconCenter" />
                          <span>Project</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2019</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockLeft betweenContentsMinus300">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          ZIMT (Center for Information and Media Technology)
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        The ZIMT provides services for students, teachers,
                        researchers, the rectorate, the central administration,
                        other central institutions, guests, those seeking
                        information, state ministries and external customers.
                        The spectrum of tasks to be mastered by the ZIMT ranges
                        from teaching media skills through further and further
                        training offers, network and server provision, operation
                        of e-learning platforms and scientific computing to the
                        support of library and administrative processes. In the
                        area of research data management, the ZIMT advises you
                        in cooperation with the e-Science Service and provides
                        infrastructures for the creation of data management
                        plans and for the long-term storage of research data.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Part-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2018 - 2020</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Back-End
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Database Administration
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockRight">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Speak Out
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        Digital network platform for orientation and self-help with personal and social problems of students in everyday university life. The specific goal of this project is a digital platform for students, on which they can invisibly store their concerns and problems in an anonymous portal. This corresponds to a complaint/grief box, which, however, does not seem anonymous enough to us in its traditional form. In addition, in our opinion, the effort (writing a letter, nodding off the route) is too high and no longer corresponds to the digital age. The platform should filter and sort incoming requests from users according to their content using keywords and use a bot to send the appropriate instances/contact persons including their contact details back to the user(s), whereby the topic of data protection which  is addressed through anonymous access to the platform should be taken into account. No data is collected that could be used to identify the user, which is intended to be supported by the university's internal server use.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Part-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2018 - 2020</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>University Siegen, Germany</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology: Confidence</span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockLeft betweenContentsMinus200">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Dzevris Merkani
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        Since 2001 years The Company Dzevris Merkani has been producing wood products from different special breeds of Georgian woods. Since 2009  the company has been taking with low price, natural materials and quality a place in the Georgian marketplace. Main priority is to produce products which will be satisfactory to the  costumers. Dzevris Merkani performs all the stages of processing until the final product, therefore Dzevris Merkani is not dependent on other companies and institutions.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <WorkIcon className="iconCenter" />
                          <span>Work (Part-Time)</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2015-2016</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>Terjola, Georgia</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>
                            Used Technology:
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              Javascript
                            </Button>
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              HTML
                            </Button>
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              CSS
                            </Button>
                          </span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Front-End
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockRight">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Dzevri Innovation
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                        Dzevri Innovation is a student union in Georgia. In particular, in the village of Dzevri in the Terjola region. The goal of the student union is to create various projects in the village using microcontrollers. The projects are tailored to the needs of the people and the village and a small budget is required for its implementation. For example, such projects are: water irrigation system, greenhouse cooling system, drone flying, etc.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <SchoolIcon className="iconCenter" />
                          <span>Project</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2015 - 2016</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>Dzevri, Georgia</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>Used Technology:</span>
                          <Button
                            disableRipple
                            className="technologyButton"
                            variant="outlined"
                            sx={{
                              color: "#77c3ec",
                              border: "1px solid #77c3ec",
                              minHeight: "20px",
                              "&:hover": {
                                cursor: "context-menu",
                                border: "1px solid #77c3ec",
                                transition: "0.4s ease-in-out",
                                background: "#77c3ec",
                                color: "rgb(14, 14, 17)",
                              },
                            }}
                          >
                            C
                          </Button>
                          <Button
                            disableRipple
                            className="technologyButton"
                            variant="outlined"
                            sx={{
                              color: "#77c3ec",
                              border: "1px solid #77c3ec",
                              minHeight: "20px",
                              "&:hover": {
                                cursor: "context-menu",
                                border: "1px solid #77c3ec",
                                transition: "0.4s ease-in-out",
                                background: "#77c3ec",
                                color: "rgb(14, 14, 17)",
                              },
                            }}
                          >
                            Java
                          </Button>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Embedded Systems
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="timelineBlock timelineBlockLeft betweenContentsMinus200">
          <div className="marker"></div>
          <div className="timelineContent">
            <Card
              sx={{
                backgroundColor: "rgb(24, 36, 68)",
                borderRadius: 5,
              }}
            >
              <div className="projectsCardOutterContainer projectsOutterStyles">
                <div className={opacity ? "show" : "hide"}>
                  <div className="projectsCardContentContainer">
                    <div className="projectsCardHeaderCenter">
                      <div className="projectsTextHeaderEffect">
                        <Typography gutterBottom variant="h4" component="div">
                          Imitation Modeling of Compatitive Market Equilibrium
                        </Typography>
                      </div>
                    </div>
                    <div className="projectsCardContentRight">
                      <div className="contentText">
                      The paper considers the questions of computer modeling of the influence hypothesis for demand and supply on the dynamics of market equilibrium prices. Mathematical model is worked out according to the results from the competitive market and functional scheme is plotted. Through means of a functional scheme of market equilibrium and a mathematical model the system computer model Simulink is composed as a block-scheme. Automation of experiments is carried out by a MatLab program. By changing of the model parameters the analysis of the effect of demand and supply on market equilibrium is conducted. Analysis proved that when changing model parameters, the variation factor in the system increases, stable equilibrium is not achieved. Often the system is unstable and the model is rough. Creation of an imitation model and usage of the values received as a result of modeling simplifies the solution of the problem of demand and supply equilibrium search on competitive market.
                      </div>
                      <div className="contentShortDescriptionBox">
                        <div className="contentShortDescriptionItem">
                          <SchoolIcon className="iconCenter" />
                          <span>Scientific Paper / Project</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <DateRangeIcon className="iconCenter" />
                          <span>2014</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <LocationOnIcon className="iconCenter" />
                          <span>Georgian Technical University, Georgia</span>
                        </div>
                        <div className="contentShortDescriptionItem">
                          <SettingsApplicationsIcon className="iconCenter" />
                          <span>
                            Used Technology:
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              Matlab
                            </Button>
                            <Button
                              disableRipple
                              className="technologyButton"
                              variant="outlined"
                              sx={{
                                color: "#77c3ec",
                                border: "1px solid #77c3ec",
                                minHeight: "20px",
                                "&:hover": {
                                  cursor: "context-menu",
                                  border: "1px solid #77c3ec",
                                  transition: "0.4s ease-in-out",
                                  background: "#77c3ec",
                                  color: "rgb(14, 14, 17)",
                                },
                              }}
                            >
                              Simulink
                            </Button>
                          </span>
                        </div>
                      </div>
                      <div className="contentHighlights">
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          Datascience
                        </Button>
                        <Button
                          disableRipple
                          className="highlightButton"
                          variant="outlined"
                          sx={{
                            color: "rgba(100,255,218,255)",
                            border: "1px solid rgba(100,255,218,255)",
                            "&:hover": {
                              cursor: "context-menu",
                              border: "1px solid rgba(100,255,218,255)",
                              transition: "0.4s ease-in-out",
                              background: "rgba(100,255,218,255)",
                              color: "rgb(14, 14, 17)",
                            },
                          }}
                        >
                          E-Commerce
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Projects;
