import React, { useState, useEffect } from "react";
import OverviewStatistics from "./../Education/EducationStatistics/Overview";
import MasterStatistics from "./../Education/EducationStatistics/Master";
import DSHStatistics from "./../Education/EducationStatistics/DSH";
import GermanStatistics from "./../Education/EducationStatistics/German";
import BachelorStatistics from "./../Education/EducationStatistics/Bachelor";
import HighSchoolStatistics from "./../Education/EducationStatistics/HighSchool";
import MiddleSchoolStatistics from "./../Education/EducationStatistics/MiddleSchool";

import Box from "@mui/material/Box";

const Right = ({ state }) => {
  const [opacity, setOpacity] = useState(false);

  useEffect(() => {
    setOpacity(false);
    setTimeout(() => {
      setOpacity(true);
    }, 1);
    /*
    const inpEl = document.querySelector(".EducationRightCaption");
    inpEl.classList.add("animateDiv");
    setTimeout(() => {
      inpEl.classList.remove("animateDiv");
    }, 3000);
    */
  }, [state]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "auto",
        minHeight: 690,
        overflow: "auto",
        textAlign: "left",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <div>
        {state === 0 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption">
                <span className="bigText">
                  <i>
                    "Only education was and is the source of happiness and
                    well-being of all people"
                  </i>
                </span>
                <span className="middleText"> - Akaki Tsereteli</span>
              </div>

              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  I always like to keep learning and improving my skills and knowledge. I discovered that as I learn more, I become even more interested in learning, and I can better use what I know in the real world. Learning has been really important to me, and I am thankful for the chances I have had to keep learning. I believe that my learning will continue throughout my whole life, and I am excited to see where it will lead me in the future.
                </span>
                <br />
                <span className="middleText">
                  On this page you can get informed about the process of my academic education, from elementary school to the present day. The Learning process is represented by numbers and statistics.
                </span>
              </div>
            </div>

            <OverviewStatistics />
          </div>
        ) : state === 1 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  The Master of Science in Informatics program at the University of Siegen has been incredibly valuable for me. While studying, I learned a lot about medical informatics, embedded systems, and data science. I was very lucky to get two scholarships and an extra certificate. The knowledge and skills I have acquired from studying at the University of Siegen will be beneficial for my future career.
                </span>
              </div>

              <div className="EducationRightCaption textLeft">
                <MasterStatistics />
              </div>
            </div>
          </div>
        ) : state === 2 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  The DSH exam is a test that universities use to see if applicants have good enough German language skills to study at a university where German is the main language. The test is designed to evaluate the applicant's ability to use and comprehend German language in an academic context. To get ready for the DSH exam, I went to the University of Siegen for one semester. During that time, I made my German language skills better.
                </span>
              </div>
              <div className="EducationRightCaption textLeft">
                <DSHStatistics />
              </div>
            </div>
          </div>
        ) : state === 3 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                While I was studying for my bachelor's degree, I made the decision to pursue my career and studies in Germany. To start preparing for this, I began learning the German language at the Goethe Institute in Tbilisi. I really wanted to learn the language well and learn more about the country's culture and customs. I also joined in different cultural activities and events arranged by the school, which helped me to learn more about the German culture. 
                </span>
              </div>
              <div className="EducationRightCaption textLeft">
                <GermanStatistics />
              </div>
            </div>
          </div>
        ) : state === 4 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  After school, I decided to start studying at the Faculty of Informatics, because I was very attracted to computer technology.
                  At the beginning of my studies, I had difficulties.
                  However, I was determined to learn and improve my skills, so studied every day.
                  By the third semester, I was able to receive a scholarship, which was a great motivation for me.
                  During my studies, I gained a lot of theoretical and practical knowledge in web/programming, management and datascience.
                </span>
              </div>
              <div className="EducationRightCaption textLeft">
                <BachelorStatistics />
              </div>
            </div>
          </div>
        ) : state === 5 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  During my high school studies, I had mostly good grades in technical subjects.
                  At that time I had already decided on my future profession and continued to develop my skills in this direction.
                  I took extra classes in physics and mathematics to gain a better understanding of the concepts and principles that I would need to know for my chosen field.
                  As a result, I was able to get into a prestigious university and continue my studies in the field of my choice.
                </span>
              </div>
              <div className="EducationRightCaption textLeft">
                <HighSchoolStatistics />
              </div>
            </div>
          </div>
        ) : state === 6 ? (
          <div className="educationText">
            <div className={opacity ? "show" : "hide"}>
              <div className="EducationRightCaption textLeft">
                <span className="middleText">
                  From the first day of my middle school studies, I had the determination and motivation to be a successful student.
                  Physics and mathematics were the most interesting and full of mysteries for me, that's why I always loved to learn and understand new things.
                  I was able to participate in various competitions and activities related to physics and mathematics.
                  This experience gave me the confidence to pursue higher studies in the field of physics and mathematics.
                </span>
              </div>
              <div className="EducationRightCaption textLeft">
                <MiddleSchoolStatistics />
              </div>
            </div>
          </div>
        ) : (
          <div> Error! </div>
        )}
      </div>
    </Box>
  );
};
export default Right;
