import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

import { Link } from "react-router-dom";

import "./NavBar.css";

import { Route, Routes, useLocation } from "react-router-dom";
import AboutMe from "../Pages/AboutMe/AboutMe";
import Projects from "../Pages/Projects/Projects";
import Contact from "../Pages/Contact/Contact";
import Education from "./../Pages/Education/Education.js";
import Skills from "./../Pages/Skills/Skills";
import NotFound from "./../Pages./../Pages/NotFound.js";

import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";

import { ThemeProvider, createTheme } from "@mui/material/styles";

/* Import image */
import ProfilePicture from "./../../Img/Me.jpg";

/* Import Icons */
import { default as GitHub } from "./../../Img/icons/github.jpg";
import { default as LinkedIn } from "./../../Img/icons/linkedin.svg";
import { default as Xing } from "./../../Img/icons/xing.png";
import { default as ResearchGate } from "./../../Img/icons/researchgate.svg";
import { default as LetCode } from "./../../Img/icons/LetCode.png";

import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;

export default function NavBar(props) {
  const desktopTheme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "transparent",
          },
        },
      },
    },
  });

  const mobileTheme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "rgb(30, 52, 81)",
          },
        },
      },
    },
  });

  // define page titles and include it in Appbar *** START
  const pageTitles = {
    "/": "About Me",
    "/aboutme": "About Me",
    "/education": "Education",
    "/skills": "Skills",
    "/projects": "Projects",
    "/contactme": "Contact",
  };

  const location = useLocation();
  const [pageTitle, setTitle] = useState(pageTitles["/"]);

  useEffect(() => {
    setTitle(pageTitles[location.pathname]);
    // eslint-disable-next-line
  }, [location.pathname]);

  // define page titles and include it in Appbar *** END,  After this we can use variable=pageTitle below

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const itemsList = [
    {
      text: "About",
      icon: <PermIdentityOutlinedIcon style={{ fill: "#85CEE9" }} />,
      to: "/aboutme",
    },
    {
      text: "Education",
      icon: <SchoolOutlinedIcon style={{ fill: "#85CEE9" }} />,
      to: "/education",
    },
    {
      text: "Skills",
      icon: <PsychologyOutlinedIcon style={{ fill: "#85CEE9" }} />,
      to: "/skills",
    },
    {
      text: "Projects",
      icon: <IntegrationInstructionsOutlinedIcon style={{ fill: "#85CEE9" }} />,
      to: "/projects",
    },
    {
      text: "Contact",
      icon: <AlternateEmailOutlinedIcon style={{ fill: "#85CEE9" }} />,
      to: "/contactme",
    },
  ];

  const drawer = (
    <div className="drawerHeight">
      <ListItem component={Link} to={"/aboutme"}>
        <div className="logoContainer">
          <img src={ProfilePicture} alt="" className="profileLogo" />
        </div>
      </ListItem>
      <div className="menuVerticalCenter">
        <Toolbar />
        <List>
          {itemsList.map((item, index) => {
            const { text, icon } = item;
            return (
              <ListItemButton
                disableRipple
                sx={{
                  width: "240px",
                  "&.Mui-selected": {
                    backgroundColor: "rgba(53, 135, 116, 0.4)",
                    "&:hover": {
                      backgroundColor: "rgba(53, 135, 116, 0.4)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(53, 135, 116, 0.4)",
                  },
                }}
                component={Link}
                to={item.to}
                key={text}
                onClick={mobileOpen ? handleDrawerToggle : null}
                selected={
                  location.pathname === "/"
                    ? item.to === "/aboutme"
                    : location.pathname === item.to
                }
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} style={{ color: "white" }} />
              </ListItemButton>
            );
          })}
        </List>
      </div>
      <div className="menuVericalBottom">
        <div className="container">
          <ul id="showContacts">
            <a
              className="menu-button"
              id="open-menu"
              href="#showContacts"
              title="Show navigation"
            >
              <div className="textOnButton textOnButtonIconBefore">
                <TravelExploreIcon
                  size="large"
                  className="textOnButtonIconBefore"
                  style={{ fill: "rgba(204,214,246,255)", fontSize: "200%" }}
                />
              </div>
            </a>

            <a
              className="menu-button textOnButtonIconAfter"
              href="#closeContacts"
              title="Hide navigation"
            >
              <div className="textOnButton">
                <CloseIcon
                  size="large"
                  className="textOnButtonIconAfter"
                  style={{ fill: "rgba(204,214,246,255)", fontSize: "200%" }}
                />
              </div>
            </a>
            <li className="menu-item">
              <ListItem
                component={Link}
                target="_blank"
                to={"https://github.com/EmzariAbzhandadze"}
              >
                <img className="contactLogos" alt="" src={GitHub} />
              </ListItem>
            </li>
            <li className="menu-item">
              <ListItem
                component={Link}
                target="_blank"
                to={"https://de.linkedin.com/in/emzari-abzhandadze-2492b0ab"}
              >
                <img className="contactLogos" alt="" src={LinkedIn} />
              </ListItem>
            </li>
            <li className="menu-item">
              <ListItem
                component={Link}
                target="_blank"
                to={"https://www.xing.com/profile/Emzari_Abzhandadze"}
              >
                <img className="contactLogos" alt="" src={Xing} />
              </ListItem>
            </li>

            <li className="menu-item">
              <ListItem
                component={Link}
                target="_blank"
                to={"https://www.researchgate.net/profile/Emzari-Abzhandadze"}
              >
                <img className="contactLogos" alt="" src={ResearchGate} />
              </ListItem>
            </li>

            <li className="menu-item">
              <ListItem
                component={Link}
                target="_blank"
                to={"https://leetcode.com/emzari_abzh/"}
              >
                <img className="contactLogos" alt="" src={LetCode} />
              </ListItem>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position="fixed"
          style={{ background: "rgb(30, 52, 81)", boxShadow: "none" }}
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {pageTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      </Slide>

      <ThemeProvider theme={mobileOpen ? mobileTheme : desktopTheme}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderWidth: 0,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderWidth: 0,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </ThemeProvider>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<AboutMe />}>
            {" "}
          </Route>
          <Route exact path="/projects" element={<Projects />}>
            {" "}
          </Route>
          <Route exact path="/aboutme" element={<AboutMe />}>
            {" "}
          </Route>
          <Route exact path="/contactme" element={<Contact />}>
            {" "}
          </Route>
          <Route exact path="/education" element={<Education />}>
            {" "}
          </Route>
          <Route exact path="/skills" element={<Skills />}>
            {" "}
          </Route>
          <Route path="" element = {<NotFound />} />
          <Route path="*" element = {<NotFound />} />
          <Route element = {<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}
