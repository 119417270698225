import React from "react";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

import Grid from "@mui/material/Grid";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const labelsBar = [
  "Literatur",
  "Mathematics",
  "Chemistery",
  "Physics",
  "Russian",
  "German",
  "History",
  "Geographics",
  "Biology",
];

const optionsBar = {
  maintainAspectRatio: false,
  scales: {
    r: {
      grid: {
        color: "rgb(246, 246, 246)",
      },
      pointLabels: {
        color: "rgb(246, 246, 246)",
      },
      ticks: {
        color: "white",
        backdropColor: "rgba(1, 1, 1, 0.5)",
        mirror: true,
        z: 1,
      },
      angleLines: {
        color: "rgb(246, 246, 246)",
        display: true,
      },
      scaleSteps: 1,
      suggestedMin: 0,
      suggestedMax: 10,
    },
  },
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Bar Chart",
    },
  },
};

const dataBar = {
  labels: labelsBar,
  datasets: [
    {
      label: "",
      data: [8, 10, 8, 10, 9, 10, 9, 9, 10],
      backgroundColor: "rgba(100,255,218, 0.8)",
      borderColor: "rgba(30, 52, 81, 1)",
      borderWidth: 1,
    },
  ],
};

const render = (
  <div>
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={8}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="statisticContainerOut">
          <Radar data={dataBar} options={optionsBar} />
        </div>
      </Grid>
    </Grid>
  </div>
);

const MiddleSchool = () => {
  return (
    <div>
      <div>{render}</div>
    </div>
  );
};
export default MiddleSchool;
