import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import "./Education.css";
import "./../../Pages/Pages.css";

import { default as UniversitySiegen } from "./Images/University Siegen.jpg";
import { default as GoetheInstitutTbilisi } from "./Images/Goethe Institut Tbilisi.jpg";
import { default as GeorgianTechnicalUniversity } from "./Images/Georgian Technical University.jpg";
import { default as ZedaSimoneti } from "./Images/Zeda Simoneti.jpg";

import MasterRecords from "./Master/TranscriptOfRecords";
import BachelorRecords from "./Bachelor/TranscriptOfRecords";

import { makeStyles } from "@material-ui/core/styles";

/* Determinate if mobile View on small screen */

import useMediaQuery from "@mui/material/useMediaQuery";



const styleMasterModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  overflowY: "scroll",
  maxHeight: "90%",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  indicator: {},
  tabs: {
    // "& button[aria-selected='true']": {
    //   border: "5px solid red"
    // }

    "& button": {
      padding: 10,
      paddingLeft: 20,
      color: "rgba(204,214,246,255)",
      width: "150px",
      position: "relative",
      borderLeft: "5px solid rgba(204,214,246,255)",
      marginLeft: 5,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      textTransform: "none",
      alignItems: "flex-start",
    },

    "& button:hover": {
      backgroundColor: "rgb(46, 70, 110)",
    },
    "& button.Mui-selected": {
      //color: "green",
      borderLeft: "5px solid rgba(100,255,218,255)",
      color: "rgba(100,255,218,255)",
    },

    "& .MuiTabs-indicator": {
      width: "0",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = ({ state, setState }) => {
  const handleChange = (event, newValue) => {
    setState(newValue);
  };

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const openMasterRecords = () => setOpen(true);
  const closeMasterRecords = () => setOpen(false);
  const openBachelorRecords = () => setOpen(true);
  const closeBachelorRecords = () => setOpen(false);

  const smallScreen = useMediaQuery("(max-width: 1000px)");
  const tabpanelDirection = smallScreen ? "horizontal" : "vertical";
  const flexGrow = smallScreen ? "unset" : "1";
  const display = smallScreen ? "relative" : "flex";
  const wrap = smallScreen ? "wrap" : "no-wrap";

  return (
    <div className="scroll-box">
      <Box
        sx={{
          flexGrow: { flexGrow },
          bgcolor: "background.paper",
          display: { display },
          height: 650,
          overflow: "auto",
          textAlign: "left",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <div className="itemsVerticalCenter">
          <Tabs
            orientation={tabpanelDirection}
            variant="scrollable"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
            value={state}
            onChange={handleChange}
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: wrap,
              },
            }}
          >
            <Tab disableRipple label="Overview" {...a11yProps(0)} />
            <Tab disableRipple label="Master" {...a11yProps(1)} />
            <Tab disableRipple label="DSH" {...a11yProps(2)} />
            <Tab disableRipple label="German" {...a11yProps(3)} />
            <Tab disableRipple label="Bachelor" {...a11yProps(4)} />
            <Tab disableRipple label="High School" {...a11yProps(5)} />
            <Tab disableRipple label="Middle School" {...a11yProps(6)} />
          </Tabs>
        </div>
        <TabPanel value={state} index={0}>
          <div className="educationText">
            <h1 className="educationTextH">Overview</h1>
            <h4 className="marginH4">Master</h4>
            <span>Informatics, University Siegen 2017 - 2021</span>
            <h4 className="marginH4">DSH</h4>
            <span>Scientific German, University Siegen 2017 - 2017</span>
            <h4 className="marginH4">German Course</h4>
            <span>German, Goethe Institut Tbilisi, 2013 - 2016</span>
            <h4 className="marginH4">Bachelor</h4>
            <span>Informatics, Georgian Technical University 2011 - 2015</span>
            <h4 className="marginH4">High School</h4>
            <span>Davit Kldiashvili Public School, 2008 - 2011</span>
            <h4 className="marginH4">Middle School</h4>
            <span>Bediauri School, 1999 - 2008</span>
          </div>
        </TabPanel>
        <TabPanel value={state} index={1}>
          <div className="educationText">
            <h1 className="educationTextH">Master (M. Sc.)</h1>
            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>University Siegen, Siegen, Germany</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>2017 - 2021</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>1.8 (Max: 1.0) ~ 88%</span>
            </div>
            <div>
              <span>
                <b>Master thesis: </b>
              </span>
              <span>
                Assistance system for people <br />
                with mental and physical disabilities by Internet-of-Things
              </span>
            </div>
            <div>
              <span>
                <b>Master Highlights: </b>
              </span>
              <span>
                <br />
                <PlayArrowIcon
                  sx={{
                    width: "25px",
                    paddingTop: "10px",
                    color: "rgba(100,255,218,255)",
                  }}
                ></PlayArrowIcon>
                Certificate: Sustainable business thinking and acting <br />
                <PlayArrowIcon
                  sx={{
                    width: "25px",
                    paddingTop: "10px",
                    color: "rgba(100,255,218,255)",
                  }}
                ></PlayArrowIcon>
                Scholarship: House of Young Talents <br />
                <PlayArrowIcon
                  sx={{
                    width: "25px",
                    paddingTop: "10px",
                    color: "rgba(100,255,218,255)",
                  }}
                ></PlayArrowIcon>
                Scholarship: Georgische Gemeinde e.V.
              </span>
            </div>
            <div>
              <span>
                <b>Transcript of Records: </b>
              </span>
              <span>
                <Button
                  disableRipple
                  onClick={openMasterRecords}
                  variant="outlined"
                  sx={{
                    color: "rgba(100,255,218,255)",
                    border: "1px solid rgba(100,255,218,255)",
                    "&:hover": {
                      border: "1px solid rgba(100,255,218,255)",
                      transition: "0.4s ease-in-out",
                      background: "rgba(100,255,218,255)",
                      color: "rgb(14, 14, 17)",
                    },
                  }}
                >
                  Grades
                </Button>
                <Modal
                  open={open}
                  onClose={closeMasterRecords}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ overflow: "scroll" }}
                  disableScrollLock
                  disableRestoreFocus
                >
                  <Box sx={styleMasterModal}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      align={"center"}
                    >
                      <CloseIcon
                        aria-label="close"
                        onClick={closeMasterRecords}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      ></CloseIcon>
                      Transcript of Records
                    </Typography>
                    <MasterRecords />
                  </Box>
                </Modal>
              </span>
            </div>
          </div>
          <div>
            <img className="educationPicture" alt="" src={UniversitySiegen} />
          </div>
        </TabPanel>
        <TabPanel value={state} index={2}>
          <div className="educationText">
            <h1 className="educationTextH">DSH</h1>
            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>University Siegen, Siegen, Germany</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>2017 - 2017</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>DSH 2 (C1)</span>
            </div>
          </div>
          <div>
            <img className="educationPicture" alt="" src={UniversitySiegen} />
          </div>
        </TabPanel>
        <TabPanel value={state} index={3}>
          <div className="educationText">
            <h1 className="educationTextH">German</h1>
            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>Goethe Institut Tbilisi</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>2013 - 2016</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>B2</span>
            </div>
          </div>
          <div>
            <img
              className="educationPicture"
              alt=""
              src={GoetheInstitutTbilisi}
            />
          </div>
        </TabPanel>
        <TabPanel value={state} index={4}>
          <div className="educationText">
            <h1 className="educationTextH">Bachelor (B. Sc.)</h1>

            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>Georgian Technical University</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>2011 - 2015</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>92%</span>
            </div>
            <div>
              <span>
                <b>Bachelor Highlight: </b>
              </span>
              <br />
              <PlayArrowIcon
                sx={{
                  width: "25px",
                  paddingTop: "10px",
                  color: "rgba(100,255,218,255)",
                }}
              ></PlayArrowIcon>
              <span>Imitation Modeling of Compatitive Market Equilibrium</span>
            </div>
            <div>
              <span>
                <b>Transcript of Records: </b>
              </span>
              <span>
                <Button
                  disableRipple
                  onClick={openBachelorRecords}
                  variant="outlined"
                  sx={{
                    color: "rgba(100,255,218,255)",
                    border: "1px solid rgba(100,255,218,255)",
                    "&:hover": {
                      border: "1px solid rgba(100,255,218,255)",
                      transition: "0.4s ease-in-out",
                      background: "rgba(100,255,218,255)",
                      color: "rgb(14, 14, 17)",
                    },
                  }}
                >
                  Grades
                </Button>
                <Modal
                  open={open}
                  onClose={closeBachelorRecords}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ overflow: "scroll" }}
                  disableScrollLock
                  disableRestoreFocus
                >
                  <Box sx={styleMasterModal}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      align={"center"}
                    >
                      <CloseIcon
                        aria-label="close"
                        onClick={closeBachelorRecords}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      ></CloseIcon>
                      Transcript of Records
                    </Typography>
                    <BachelorRecords />
                  </Box>
                </Modal>
              </span>
            </div>
          </div>
          <div>
            <img
              alt=""
              className="educationPicture"
              src={GeorgianTechnicalUniversity}
            />
          </div>
        </TabPanel>
        <TabPanel value={state} index={5}>
          <div className="educationText">
            <h1 className="educationTextH">High School</h1>
            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>Davit Kldiashvili Public School, Terjola, Georgia</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>2008 - 2011</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>8,9/10</span>
            </div>
          </div>
          <div>
            <img className="educationPicture" alt="" src={ZedaSimoneti} />
          </div>
        </TabPanel>
        <TabPanel value={state} index={6}>
          <div className="educationText">
            <h1 className="educationTextH">Middle School</h1>
            <div>
              <span>
                <b>Place: </b>
              </span>
              <span>Bediauri School, Terjola, Georgia</span>
            </div>
            <div>
              <span>
                <b>Dates: </b>
              </span>
              <span>1999 - 2008</span>
            </div>
            <div>
              <span>
                <b>Grade: </b>
              </span>
              <span>9.3/10</span>
            </div>
          </div>
          <div>
            {/* <img className="educationPicture" alt="" src={ZedaSimoneti} /> */}
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};

export default VerticalTabs;
