import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";

function createData(module, grade) {
  return { module, grade };
}

const firstRow = [
  createData("Telematics - Multimedia", "2.7"),
  createData("Telematics - technologies and applications", "1.7"),
  createData("Computer Networks II", "4.0"),
  createData("Computer Architectures II", "2.7"),
  createData("Database Systems II", "4.0"),
  createData("Seminar Paper - Internet of Things", "1.0"),
  createData("Embedded Systems", "1.7"),
  createData("Storage Technologies", "1.3"),
];

const secondRow = [
  createData("High-Tech-Medicine II", "3.3"),
  createData("Knowledge Management II", "2.7"),
  createData("Knowledge-based systems II", "1.7"),
  createData("Ubiquitous Computing", "2.3"),
  createData("Industrial Communications", "2.7"),
  createData("Microsystems Design - Manufacturing", "1.0"),
  createData(
    "Project - Supply via the digital Doctor's Office: Patient-side vital data collection with intelligent, cloud-based evaluation for the doctor",
    "1.0"
  ),
  createData(
    "Master Thesis - Internet-of-Things-based assistance system for people with mental and physical limitations",
    "1.0"
  ),
];

const TranscriptOfRecords = () => {
  const [state] = React.useState(0);

  React.useEffect(() => {
    /* console.log(state) */
  }, [state]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Module</b>
                </TableCell>
                <TableCell align="right">
                  <b>Note</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstRow.map((row) => (
                <TableRow
                  key={row.module}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.module}
                  </TableCell>
                  <TableCell align="right">{row.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={6}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Module</b>
                </TableCell>
                <TableCell align="right">
                  <b>Note</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {secondRow.map((row) => (
                <TableRow
                  key={row.module}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.module}
                  </TableCell>
                  <TableCell align="right">{row.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TranscriptOfRecords;
