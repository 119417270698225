import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import "./../Education/Education.css";
import "./../Pages.css";

import EducationDetails from "./EducationDetails";
import EducationStatistics from "./EducationStatistics";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1475,
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const Education = () => {
  const [state, setState] = React.useState(0);
  React.useEffect(() => {
    /* console.log(state) */
  }, [state]);

  return (
    <div className="mainContainer">
      <ThemeProvider
        theme={responsiveFontSizes(breakpoints, {
          breakpoints: ["xs", "sm", "md", "lg", "xl"],
        })}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 12 }}
          display="flex"
          justifyContent="left"
          direction="row"
          alignItems="center"
          minHeight="75vh"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            style={{ height: "100%", width: "50%" }}
          >
            <Item>
              <EducationDetails state={state} setState={setState} />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            style={{ height: "100%", width: "50%" }}
          >
            <Item>
              <EducationStatistics state={state} />
            </Item>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

/*
class Education extends Component {
  render() {
    const { medicalPosts, auth } = this.props;

    return (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        display="flex"
        justifyContent="left"
        direction="row"
        alignItems="center"
        minHeight="75vh"
      >
        <Grid item xs={8} sm={6} md={6}>
          <Item>
            <EducationDetails />
          </Item>
        </Grid>
        <Grid item xs={8} sm={6} md={6}>
          <Item>
            <EducationStatistics />
          </Item>
        </Grid>
      </Grid>
    );
  }
}

*/

export default Education;
