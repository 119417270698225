import React, { useState, useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./../Pages.css";
import "./Skills.css";

import { default as JS } from "./Logos/JS.svg";
import { default as TS } from "./Logos/TypeScript.png";
import { default as Angular } from "./Logos/Angular.png";
import { default as CSS } from "./Logos/CSS3.png";
import { default as HTML } from "./Logos/HTML5.png";
import { default as PHP } from "./Logos/PHP.png";
import { default as ReactLogo } from "./Logos/React.png";
import { default as SASS } from "./Logos/SASS.png";
import { default as NET } from "./Logos/NET.png";
import { default as Python } from "./Logos/Python.png";
import { default as Node } from "./Logos/Node.png";
import { default as Kotlin } from "./Logos/Kotlin.svg";
import { default as Xamarin } from "./Logos/Xamarin.png";
import { default as ReactNative } from "./Logos/React Native.svg";

import { default as SCRUM } from "./Logos/SCRUM.png";
import { default as Trello } from "./Logos/Trello.png";
import { default as JIRA } from "./Logos/JIRA.png";

import { default as SQL } from "./Logos/SQL.svg";
import { default as MySQL } from "./Logos/MYSQL.png";
import { default as JSON } from "./Logos/JSON.svg";
import { default as MongoDB } from "./Logos/MongoDB.webp";
import { default as Firestore } from "./Logos/Firestore.png";
import { default as XML } from "./Logos/XML.png";
import { default as XSLT } from "./Logos/XSLT.png";

import { default as Git } from "./Logos/GIT.svg";
import { default as WindowsBash } from "./Logos/Windows Bash.png";
import { default as LinuxBash } from "./Logos/Linux Bash.png";
import { default as CPlus } from "./Logos/C++.svg";
import { default as CSharp } from "./Logos/CSharp.svg";
import { default as Java } from "./Logos/Java.png";
import { default as Drupal } from "./Logos/Drupal.png";
import { default as WordPress } from "./Logos/WordPress.png";

/* Import Card */
import Card from "@mui/material/Card";

/* Import Progress Bar*/
import LinearProgress from "@mui/material/LinearProgress";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 900,
      lg: 1300,
      xl: 2000,
    },
  },
});

const cardWidth = { xs: 350, sm: 350, md: 300, lg: 470, xl: 900 };
const cardHeight = { xs: 420, sm: 420, md: 470, lg: 410, xl: 500 };
const mainGridSpacing = { xs: 2, sm: 4, md: 4, lg: 4, xl: 4 };
const mainGridColumns = { xs: 4, sm: 8, md: 12, lg: 12, xl: 12 };
const cardGridXS = 12;
const cardGridSM = 6;
const cardGridMD = 6;
const cardGridLG = 4;
const cardGridXL = 4;

const Education = () => {
  const [opacity, setOpacity] = useState(false);

  /*console.log(opacity);*/
  useEffect(() => {
    setOpacity(false);
    setTimeout(() => {
      setOpacity(true);
    }, 1);
  }, []);

  return (
    <div className="mainContainer">
      <Grid
        container
        spacing={mainGridSpacing}
        columns={mainGridColumns}
        display="flex"
        justifyContent="center"
        direction="row"
        alignItems="center"
        minHeight="75vh"
      >
        <ThemeProvider
          theme={responsiveFontSizes(breakpoints, {
            breakpoints: ["xs", "sm", "md", "lg", "xl"],
          })}
        >
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Front-End
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img
                            className="logos"
                            alt=""
                            src={JS}
                            title="JavaScript"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={TS}
                            title="TypeScript"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={Angular}
                            title="Angular"
                          />
                          <img className="logos" alt="" src={CSS} title="CSS" />
                          <img
                            className="logos"
                            alt=""
                            src={SASS}
                            title="SASS"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={ReactLogo}
                            title="ReactJS"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={HTML}
                            title="HTML5"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={NET}
                            title=".Net MVC"
                          />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Cross-Browser Compability
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Responsive Design
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              value={98}
                              valueBuffer={100}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Testing & Debugging
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                                left: 0,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>
                        Working experience: 6<sup>+</sup> Years
                      </div>
                      <div>
                        Practical experience: 10<sup>+</sup> Years
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Back-End
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img className="logos" alt="" src={PHP} title="PHP" />
                          <img
                            className="logos"
                            alt=""
                            src={Python}
                            title="Python"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={NET}
                            title=".Net MVC"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={Node}
                            title="NodeJS"
                          />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                System Security
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={81}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                System Logic Knowlegende
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Sorting Functions
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>
                        Working experience: 2<sup>+</sup> Years
                      </div>
                      <div>
                        Practical experience: 3<sup>+</sup> Years
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Database
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img className="logos" alt="" src={SQL} title="SQL" />
                          <img
                            className="logos"
                            alt=""
                            src={MySQL}
                            title="MySQL"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={JSON}
                            title="JSON"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={MongoDB}
                            title="MongoDB"
                          />
                          <img
                            className="logos"
                            src={Firestore}
                            title="Firestore"
                            alt=""
                          />
                          <img className="logos" alt="" src={XML} title="XML" />
                          <img
                            className="logos"
                            alt=""
                            src={XSLT}
                            title="XSLT"
                          />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Query Languages
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={81}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Analytical Thinking
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={88}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Meticulous attention to details
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>
                        Working experience: 2<sup>+</sup> Years
                      </div>
                      <div>
                        Practical experience: 10<sup>+</sup> Years
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Mobile
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img
                            className="logos"
                            alt=""
                            src={Xamarin}
                            title="Xamarin"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={Kotlin}
                            title="Kotlin"
                          />
                          <img
                            className="logos"
                            src={ReactNative}
                            title="React Native"
                            alt=""
                          />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Internet of Things (IoT)
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={97}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Agile Developing
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={98}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Cross-platform
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={83}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>Working experience: -</div>
                      <div>
                        Practical experience: 4<sup>+</sup> Years
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Project Managing
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img className="logos" alt="" src={Trello} title="Trello" />
                          <img className="logos" alt="" src={JIRA} title="Jira" />
                          <img className="logos" alt="" src={SCRUM} title="Scrum" />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Project planning
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={90}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Risk Management
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={90}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Project Budgeting
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              value={50}
                              valueBuffer={100}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>
                        Working experience: 2<sup>+</sup> Years
                      </div>
                      <div>Practical experience: -</div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            xs={cardGridXS}
            sm={cardGridSM}
            md={cardGridMD}
            lg={cardGridLG}
            xl={cardGridXL}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Card
                className="cardContainer"
                sx={{
                  width: cardWidth,
                  height: cardHeight,
                  backgroundColor: "rgb(24, 36, 68)",
                  borderRadius: 5,
                }}
              >
                <div className="cardOutterContainer outterStyles">
                  <div className={opacity ? "show" : "hide"}>
                    <div className="cardContentContainer">
                      <div className="cardHeader">
                        <div className="textEffect">
                          <Typography gutterBottom variant="h3" component="div">
                            Other
                          </Typography>
                        </div>

                        <div className="divLogos">
                          <img
                            className="logos"
                            alt=""
                            src={CPlus}
                            title="C++"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={CSharp}
                            title="C#"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={Java}
                            title="Java"
                          />
                          <img
                            className="logos"
                            src={WindowsBash}
                            title="Windows Bash"
                            alt=""
                          />
                          <img
                            className="logos"
                            src={LinuxBash}
                            title="Linux Bash"
                            alt=""
                          />
                          <img className="logos" alt="" src={Git} title="GIT" />
                          <img
                            className="logos"
                            alt=""
                            src={Drupal}
                            title="Drupal"
                          />
                          <img
                            className="logos"
                            alt=""
                            src={WordPress}
                            title="WordPress"
                          />
                        </div>
                        <div className="divProgressbars">
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Server Administration
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={90}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                Version Controlling
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={99}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                          <div className="divProgressbar">
                            <span>
                              <Typography variant="body1">
                                User Interface (UI)
                              </Typography>
                            </span>
                            <LinearProgress
                              variant="buffer"
                              valueBuffer={100}
                              value={80}
                              sx={{
                                backgroundColor: "rgba(204,214,246,255)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "rgba(100,255,218,255)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience textEffect">
                      <div>
                        Working experience: -
                      </div>
                      <div>
                        Practical experience: 10<sup>+</sup> Years
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Box>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default Education;
