import * as React from "react";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function GuestFooter() {
  return (
    <Paper
      sx={{
        marginTop: "calc(10% + 10px)",
        width: "100%",
        position: "fixed",
        bottom: 0,
        height: 0,
        borderBottom: "none",
      }}
      component="footer"
      square // variant="outlined"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        >
          <div>
            <Typography variant="caption" color="initial">
              Copyright ©2022. [] Limited
            </Typography>
          </div>
        </Box>
      </Container>
    </Paper>
  );
}
