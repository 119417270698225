import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";

function createData(module, grade) {
  return { module, grade };
}

const firstRow = [
  createData("Mathematic", 75),
  createData("Programming Basics", 80),
  createData("Georgian History", 78),
  createData("Introduction in Information Technologies", 86),
  createData("English language", 72),
  createData("Fundamentals of Computer Architecture and Organization", 86),
  createData("Engineering Graphics", 83),
  createData("Physics", 83),
  createData("Discrete Mathematics", 86),
  createData("Object-oriented Programming", 98),
  createData("Data Structures and Programming", 59),
  createData("Personal Application Systems", 93),
  createData("Operating Systems Fundamentals", 86),
  createData("Labor safety and emergency management", 91),
  createData("Computer Networks Fundamentals", 93),
  createData("Basics of Web Technologies", 91),
  createData("Administration of Computer Networks", 98),
];

const secondRow = [
  createData("Software Systems for Project Management", 100),
  createData("Principles of Economics", 96),
  createData("Bussines communication", 100),
  createData("Human Resources Management", 100),
  createData("Activate Games in Business and Management", 99),
  createData("E-Business Technologies", 100),
  createData("Information Ensuring for Business and Marketing", 100),
  createData("Informational Technology in Management", 93),
  createData("Finance Computer Technologies", 92),
  createData("Computer Graphics and Multimedia", 99),
  createData("Visual Programming", 96),
  createData("Business - Process Modeling and Design", 100),
  createData("Computer Technologies of Information Management", 95),
  createData("Principles of Public Relations", 100),
  createData("Probability theory and statistics", 94),
  createData("Environmental protection", 91),
];

const thirdRow = [
  createData("Database Management System", 98),
  createData("Social Processes Computer Simulation", 100),
  createData("Decision Making Support Systems in Business and Marketing", 98),
  createData("Information protection Methods and systems", 97),
  createData("Informatics and society laws", 94),
  createData("Information Technologies of Economic Analysis", 98),
  createData("Business computer data analysis", 98),
  createData("Extended Markup Language XML", 95),
  createData("Web-programming Technology: PHP", 98),
  createData(
    "Interactive decision making support in conflict situation systems",
    89
  ),
  createData("Intelligence Systems", 100),
  createData("Basics of Psychometrics", 100),
  createData("Development of Social Networks", 100),
  createData("Fundamentals of Building Databases", 92),
  createData("Web Site Visual Design", 100),
];

const TranscriptOfRecords = () => {
  const [state] = React.useState(0);

  React.useEffect(() => {
    /* console.log(state) */
  }, [state]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Module</b>
                </TableCell>
                <TableCell align="right">
                  <b>Note</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstRow.map((row) => (
                <TableRow
                  key={row.module}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.module}
                  </TableCell>
                  <TableCell align="right">{row.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={4}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Module</b>
                </TableCell>
                <TableCell align="right">
                  <b>Note</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {secondRow.map((row) => (
                <TableRow
                  key={row.module}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.module}
                  </TableCell>
                  <TableCell align="right">{row.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={4}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Module</b>
                </TableCell>
                <TableCell align="right">
                  <b>Note</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thirdRow.map((row) => (
                <TableRow
                  key={row.module}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.module}
                  </TableCell>
                  <TableCell align="right">{row.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TranscriptOfRecords;
